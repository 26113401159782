import * as React from 'react';
import { SVGProps } from 'react';
const SvgNodata = (props: SVGProps<SVGSVGElement>) => (
  <svg width={52} height={52} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse opacity={0.3} cx={26} cy={36} rx={26} ry={6} fill="#DDD" />
    <path
      d="M44.15 20.633h-7.102c-.628 0-1.23.254-1.674.705a2.426 2.426 0 0 0-.693 1.702c0 .638-.25 1.25-.694 1.701a2.348 2.348 0 0 1-1.674.705H19.687c-.628 0-1.23-.254-1.675-.705a2.427 2.427 0 0 1-.693-1.701c0-.639-.25-1.25-.693-1.702a2.348 2.348 0 0 0-1.674-.705H7.849v14.578c0 .639.25 1.25.693 1.702a2.349 2.349 0 0 0 1.675.705h31.566c.628 0 1.23-.254 1.675-.705a2.426 2.426 0 0 0 .693-1.702V20.633Z"
      fill="#F9F9F9"
      stroke="#DDD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.85 20.634 15.292 10H35.81l8.34 10.634" stroke="#DDD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgNodata;
