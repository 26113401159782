import * as React from 'react';
import { SVGProps } from 'react';
const SvgImg = (props: SVGProps<SVGSVGElement>) => (
  <svg width={52} height={52} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.632 5h28.736A6.623 6.623 0 0 1 47 11.632v28.736A6.623 6.623 0 0 1 40.368 47H11.632A6.623 6.623 0 0 1 5 40.368V11.632A6.623 6.623 0 0 1 11.632 5Zm0 2.21a4.434 4.434 0 0 0-4.421 4.422v25.62l9.483-9.506 5.526 5.527L33.273 22.22l11.516 11.517V11.632a4.434 4.434 0 0 0-4.42-4.421H11.631ZM22.22 36.413l-5.526-5.527-9.483 9.483a4.434 4.434 0 0 0 4.42 4.421h28.737a4.434 4.434 0 0 0 4.421-4.42v-3.515L33.273 25.359 22.22 36.412Zm-5.062-24.78a5.528 5.528 0 0 1 5.526 5.526 5.528 5.528 0 0 1-5.526 5.526 5.528 5.528 0 0 1-5.526-5.526 5.528 5.528 0 0 1 5.526-5.526Zm0 2.21a3.311 3.311 0 0 0-3.316 3.316 3.311 3.311 0 0 0 3.316 3.316 3.311 3.311 0 0 0 3.316-3.316 3.311 3.311 0 0 0-3.316-3.316Z"
      fill="#A8A8A8"
    />
  </svg>
);
export default SvgImg;
