import classnames from "classnames";

interface ButtonInlineProps {
  size?: "m" | "s";
  variant?: "underline" | "regular";
}

function ButtonInline({
  size,
  className,
  variant = "regular",
  ...props
}: ButtonInlineProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={classnames(
        {
          "size-s": size === "s",
          "rounded-none border-b border-black100": variant === "underline",
          "border-none": variant !== "underline",
        },
        "bg-transparent py-2xs px-0 text-black100",
        className
      )}
      {...props}
    ></button>
  );
}

export default ButtonInline;
